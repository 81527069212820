import { tr } from "date-fns/locale";


export const Operation = Object.freeze({
  NONE: "NONE", 	// Nezadaný (0)
  ONE_OFF: "ONE_OFF", 	// Jednorázový (1)
  PERC_PRICE_DRIVE: "PERC_PRICE_DRIVE", 	//Percento k cene prepravy (2)  ==> premenovať z PERC_TRANSFER na PERC_PRICE_DRIVE
  PERC_PRICE_JOURNEY: "PERC_PRICE_JOURNEY", 	// Percento k sumárnej cene (3) ==> premenovať z PERC_SUMMARY na PERC_PRICE_JOURNEY
  DRIVE_TIME: "DRIVE_TIME", 	// Za minútu jazdy (4)
  DRIVE_DISTANCE: "DRIVE_DISTANCE", 	// Za kilometer jazdy (5)
  WAITING_TIME: "WAITING_TIME", 	// Za minútu čakania v stave 6 - za kazdu zacatu minutu (6)
  WAITING_TIME_EXACT: "WAITING_TIME_EXACT", 	// Za minútu čakania v stave 6 presne na sekundy, resp. min 3 desatinne miesta(6)
  //WAITING_PICKUP_TIME: "WAITING_PICKUP_TIME" 	// Za minútu čakania v stave 5 (5)
});

export const Automatic = Object.freeze({
  MANUAL: "MANUAL", 	// Vodič môže manuálne pridať príplatok (0)
  AUTO: "AUTO"	// Príplatok bude automaticky pridaný k účtu (1)
});

export const Unit = Object.freeze({
  KM: "KM",
  M: "M",
  S: "S",
  MIN: "MIN",
  H: "H",
  KS: "KS"
});

export const ObligatoryType = Object.freeze({
  //PICKUP_PRICE: "PICKUP_PRICE",
  PRICE_WAITING: "PRICE_WAITING",
  PRICE_JOURNEY: "PRICE_JOURNEY"
  //FARE_TIME_PRICE: "FARE_TIME_PRICE",
  //FARE_DISTANCE_PRICE: "FARE_DISTANCE_PRICE",
});

export const Type = Object.freeze({
  NONE: "NONE", 	// Nezadaný (0)
  GENERAL: "GENERAL", 	// Všeobecný poplatok (1)
  ALWAYS: "ALWAYS", 	// Vždy
  TYPE_INSTANT: "TYPE_INSTANT",
  TYPE_TIME: "TYPE_TIME",
  TYPE_STREET: "TYPE_STREET",
  //TYPE_DIRECT: "TYPE_DIRECT",
  //TYPE_REPEATED: "TYPE_REPEATED",
  //TYPE_AIRPORT: "TYPE_AIRPORT",
  WAITING: "WAITING", 	// Poplatok za čakanie (2)
  PREORDER: "PREORDER", 	// Objednávka na čas (3)
  NIGHT: "NIGHT", 	// Nočný príplatok (4)
  WEEKEND: "WEEKEND", 	// Víkendový príplatok (5)
  OFF_TIME: "OFF_TIME", 	// Nočný/víkendový príplatok (6)
  AIRPORT_TRANSPORT: "AIRPORT_TRANSPORT", 	// Letiskový príplatok (7)
  STATION_TRANSPORT: "STATION_TRANSPORT", 	// Staničný príplatok (8)
  ABROAD_TRANSPORT: "ABROAD_TRANSPORT", 	// Zahraničný príplatok (9)
  PICKUP: "PICKUP", 	// Poplatok za vyzdvihnutie 
  PICKUP_CITY: "PICKUP_CITY", 	// Vyzdvihnutie v meste (10)
  PICKUP_OUT_CITY: "PICKUP_OUT_CITY", 	// Vyzdvihnutie mimo mesta (11)
  DRINK_TRANSPORT: "DRINK_TRANSPORT", 	// Príplatok za prepravu vozidla zákazníka
  LUGGAGE_SMALL: "LUGGAGE_SMALL", 	// Malá batožina (12)
  LUGGAGE_MEDIUM: "LUGGAGE_MEDIUM", 	// Stredná batožina (12)
  LUGGAGE_BIG: "LUGGAGE_BIG", 	// Veľká batožina (12)
  PAYMENT_TERMINAL: "PAYMENT_TERMINAL", 	// Platobný terminál vo vozidle
  SPECIAL_TRANSPORT_OLD: "SPECIAL_TRANSPORT_OLD", 	// Preprava starších ľudí
  SPECIAL_TRANSPORT_CHAIR: "SPECIAL_TRANSPORT_CHAIR", 	// Preprava ľudí na vozíčku 
  AIRCONDITION: "AIRCONDITION", 	// Klimatizácia (14)
  BOOSTER: "BOOSTER", 	// Detský podsedák (15)
  SEAT: "SEAT", 	// Detská sedačka (16)
  ANIMAL_SMALL: "ANIMAL_SMALL", 	// Preprava zvieraťa (17)
  ANIMAL_MEDIUM: "ANIMAL_MEDIUM", 	// Preprava zvieraťa (17)
  ANIMAL_BIG: "ANIMAL_BIG", 	// Preprava zvieraťa (17)
  PREFERRED_DRIVER: "PREFERRED_DRIVER", 	// Preferovaný šofér (18)
  FOULING: "FOULING", 	// Znečistenie (18)
  STAINING: "STAINING", 	// Veľké znečistenie (19)
  /*VEHICLE_SEDAN: "VEHICLE_SEDAN", 	// Sedan (21)
  VEHICLE_COMBI: "VEHICLE_COMBI", 	// Combi (22)
  VEHICLE_CABRIO: "VEHICLE_CABRIO", 	// Cabrio (23)
  VEHICLE_COUPE: "VEHICLE_COUPE", 	// Coupé (24)
  VEHICLE_HATCHBACK: "VEHICLE_HATCHBACK", 	// Hatchback (25)
  VEHICLE_VAN: "VEHICLE_VAN", 	// Van (26)
  VEHICLE_MICROBUS: "VEHICLE_MICROBUS", 	// Microbus (27)
  VEHICLE_BUS: "VEHICLE_BUS", 	// Bus (28)
  VEHICLE_VAN_CARGO: "VEHICLE_VAN_CARGO", 	// Dodávka (29)
  VEHICLE_PICKUP: "VEHICLE_PICKUP", 	// Pickup (30)
  VEHICLE_SUV: "VEHICLE_SUV", 	// SUV (31) */
  PERSONS_2: "PERSONS_2", 	// Maximálny požadovaný počet osôb 2 (32)
  PERSONS_3: "PERSONS_3", 	// Maximálny požadovaný počet osôb 3 (32)
  PERSONS_4: "PERSONS_4", 	// Maximálny požadovaný počet osôb 4 (32)
  PERSONS_5: "PERSONS_5", 	// Maximálny požadovaný počet osôb 5 (32)
  PERSONS_6: "PERSONS_6", 	// Maximálny požadovaný počet osôb 6 (32)
  PERSONS_7: "PERSONS_7", 	// Maximálny požadovaný počet osôb 7 (32)
  PERSONS_8: "PERSONS_8", 	// Maximálny požadovaný počet osôb 8 (32)
  SERVICE_PERSONAL: "SERVICE_PERSONAL", 	// Osobná preprava (41)
  //SERVICE_LIMO: "SERVICE_LIMO", 	// Limuzínová preprava (42)
  //SERVICE_CARGO: "SERVICE_CARGO", 	// Nákladná preprava (43)
  //SERVICE_DRINK: "SERVICE_DRINK", 	// Drink taxi (44)
  //SERVICE_IMMOBILE: "SERVICE_IMMOBILE", 	// Preprava imobilných (45)
  //SERVICE_COURIER: "SERVICE_COURIER", 	// Kurierská služba (46)
  //SERVICE_DELIVERY: "SERVICE_DELIVERY", 	// Donášková služba (47)
  //SERVICE_AIRPORT: "SERVICE_AIRPORT", 	// Letiskové taxi (48)
  //SERVICE_SOCIAL: "SERVICE_SOCIAL", 	// Sociálne taxi (49)
  PAYMENT_CASH: "PAYMENT_CASH", 	// Hotovosť (51)
  PAYMENT_CARD: "PAYMENT_CARD", 	// Karta vo vozidle (52)
  //PAYMENT_VIAMO: "PAYMENT_VIAMO", 	// Viamo (53)
  //PAYMENT_COUPON: "PAYMENT_COUPON", 	// Kupón (54)
  PAYMENT_CARD_ONLINE: "PAYMENT_CARD_ONLINE", 	// Karta online (55)
  //PAYMENT_PREPAID: "PAYMENT_PREPAID", 	// Preplatená jazda (56)
  PAYMENT_POSTPAID: "PAYMENT_POSTPAID", 	// Platba po jazde/postpaid (57)
  // PAYMENT_INVOICE: "PAYMENT_INVOICE", 	// Platba na faktúru (58)
  PICKUP_PLACE_CHANGED: "PICKUP_PLACE_CHANGED",
  DESTINATION_PLACE_CHANGED: "DESTINATION_PLACE_CHANGED",
  WAYPOINT: "WAYPOINT", //AUTOMATICALLY ADDED
  WAYPOINT_STOP: "WAYPOINT_STOP",
  //WAYPOINT_INFO: "WAYPOINT_INFO", 	// Informácia o cene za medzizastávky (0)
  WAYPOINT_DISTRICT: "WAYPOINT_DISTRICT", 	// Extra zastávka vo štvrti (61)
  WAYPOINT_CITY: "WAYPOINT_CITY", 	// Extra zastávka v meste (62)
  WAYPOINT_LOCALITY_PRICE: "WAYPOINT_LOCALITY_PRICE", 	// Extra zastávka v lokalite
  BATTERY: "BATTERY", 	// Nabitie batérie (67)
  FUEL: "FUEL", 	// Poskytnutie paliva (68)
  DRAG_VEHICLE: "DRAG_VEHICLE", 	// Odtiahnutie vozidla (69)
  REGISTRATION: "REGISTRATION", 	// Registrácia (71)
  BLOCKING: "BLOCKING", 	// Zablokovanie (72)
  UNBLOCKING: "UNBLOCKING", 	// Odblokovanie (73)
  SOURCE_DISPATCH_TAXISERVICE: "SOURCE_DISPATCH_TAXISERVICE", 	// Objednávka z dispečingu (74)
  //SOURCE_DISPATCH_BROKER: "SOURCE_DISPATCH_BROKER", 	// Objednávka z dispečingu (74)
  //SOURCE_DISPATCH_TAXXIMO: "SOURCE_DISPATCH_TAXXIMO", 	// Objednávka z dispečingu (74)
  SOURCE_DRIVER: "SOURCE_DRIVER", 	// Objednávka od vodiča z ulice (75)
  SOURCE_APP: "SOURCE_APP", 	// Objednávka z aplikácie zákazníka (76)
  SOURCE_BRANDAPP: "SOURCE_BRANDAPP", 	// Objednávka od brandovanej aplikácie zákazníka (77)
  SOURCE_PBX: "SOURCE_PBX", 	// Objednávka z PBX
  ACCOUNT: "ACCOUNT", 	// Zmluvná/firemný jazda (78)
  SHOPPING: "SHOPPING", // "Nákup"
  //STORNO_PREREGISTERED: "STORNO_PREREGISTERED", 	 	// STORNO PREREGISTERED -> Odregistrovaná/Unregistered (88)
  //STORNO_NEW: "STORNO_NEW", 	 	// STORNO Nová -> Nerealizovaná/Expired  (81)
  //STORNO_PLANNED: "STORNO_PLANNED", 	 	// STORNO Plánovaná -> Odvolaná/Revoked  (82)
  //STORNO_PENDING: "STORNO_PENDING", 	 	// STORNO Čakajúca -> Odmietnutá/Declined  (83)
  //STORNO_ACCEPTED: "STORNO_ACCEPTED", 	 	// STORNO Akcpetovaná -> Zrušená/Canceled  (84)
  //STORNO_WAITING: "STORNO_WAITING", 	 	// STORNO Na mieste -> Zamietnutá/Dismissed  (85)
  //STORNO_IN_PROGRESS: "STORNO_IN_PROGRESS", 	 	// STORNO Realizovaná -> Nedokončená/Unfinished  (86)
  //STORNO_FINISHED: "STORNO_FINISHED", 	 	// STORNO Ukončená -> Nenaúčtovaná/Uncharged  (87)
  CUSTOMER_NEW: "CUSTOMER_NEW", 	// Nový zákazník
  CUSTOMER_REGULAR: "CUSTOMER_REGULAR", 	// Stály zákazník
  CUSTOMER_PRIORITY: "CUSTOMER_PRIORITY", 	// Prioritný zákazník
  CUSTOMER_FAMOUS: "CUSTOMER_FAMOUS", 	// Známa osobnosť
  CUSTOMER_VIP: "CUSTOMER_VIP", 	// VIP klient
  CUSTOMER_COLLEAGUE: "CUSTOMER_COLLEAGUE", 	// Kolega z taxislužby
  CUSTOMER_STUDENT: "CUSTOMER_STUDENT", 	// Študent
  CUSTOMER_CHILD: "CUSTOMER_CHILD", 	// Dieťa
  CUSTOMER_JUNIOR: "CUSTOMER_JUNIOR", 	// Junior
  CUSTOMER_SENIOR: "CUSTOMER_SENIOR", 	// Senior
  CUSTOMER_DEBTOR: "CUSTOMER_DEBTOR", 	// Dlžník
  CUSTOMER_BLOCKED: "CUSTOMER_BLOCKED",
   HOURS: "HOURS", // "Hodiny (A)"
  MONDAY: "MONDAY", // "Pondelok (A)"
  TUESDAY: "TUESDAY", // "Utorok (A)"
  WEDNESDAY: "WEDNESDAY", // "Streda (A)"
  THURSDAY: "THURSDAY", // "Štvrtok (A)"
  FRIDAY: "FRIDAY", // "Piatok (A)"
  SATURDAY: "SATURDAY", // "Sobota (A)"
  SUNDAY: "SUNDAY", // "Nedeľa (A)"
  //RIDE_DISTANCE: "RIDE_DISTANCE" // "Vzdialenosť jazdy (A)", 
  SOURCE_BRANDAPP_FIRST_TWO_ORDERS: "SOURCE_BRANDAPP_FIRST_TWO_ORDERS", 	// Objednávka od brandovanej aplikácie zákazníka 
  SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: "SOURCE_BRANDAPP_EVERY_FIVE_ORDERS", 	// Objednávka od brandovanej aplikácie zákazníka
//CYCLE_BRANDAPP: "CYCLE_BRANDAPP", // "Cyklus počtu z brand appky (A)"
  //CYCLE_APP: "CYCLE_APP", // "Cyklus počtu z appky (A)"
  //CYCLE_CALL: "CYCLE_CALL", // "Cyklus počtu cez telefón (A)"
  //CYCLE_PBX: "CYCLE_PBX", // "Cyklus počtu cez AI (A)"
  COUNTER_BRANDAPP: "COUNTER_BRANDAPP", // "Rozsah počtu z brand appky (A)"
  COUNTER_APP: "COUNTER_APP", // "Rozsah počtu z appky (A)"
  COUNTER_CALL: "COUNTER_CALL", // "Rozsah počtu cez telefón (A)"
  COUNTER_PBX: "COUNTER_PBX", // "Rozsah počtu cez AI (A)"
  MONTH_BRANDAPP: "MONTH_BRANDAPP", // "Rozsah z mesačného počtu brand appky (A)"
  MONTH_APP: "MONTH_APP", // "Rozsah z mesačného počtu appky (A)"
  MONTH_CALL: "MONTH_CALL", // "Rozsah z mesačného počtu telefonicky (A)"
  MONTH_PBX: "MONTH_PBX", // "Rozsah z mesačného počtu AI (A)"
  QUARTER_BRANDAPP: "QUARTER_BRANDAPP", // "Rozsah z štvrťročného počtu brand appky (A)"
  QUARTER_APP: "QUARTER_APP", // "Rozsah z štvrťročného počtu appky (A)"
  QUARTER_CALL: "QUARTER_CALL", // "Rozsah z štvrťročného počtu telefón (A)"
  QUARTER_PBX: "QUARTER_PBX", // "Rozsah z štvrťročného počtu AI (A)"
  HALFYEAR_BRANDAPP: "HALFYEAR_BRANDAPP", // "Rozsah z polročného počtu brand appky (A)"
  HALFYEAR_APP: "HALFYEAR_APP", // "Rozsah z polročného počtu appky (A)"
  HALFYEAR_CALL: "HALFYEAR_CALL", // "Rozsah z polročného počtu telefón (A)"
  HALFYEAR_PBX: "HALFYEAR_PBX", // "Rozsah z polročného počtu AI (A)"
  YEAR_BRANDAPP: "YEAR_BRANDAPP", // "Rozsah z ročného počtu brand appky (A)"
  YEAR_APP: "YEAR_APP", // "Rozsah z ročného počtu appky (A)"
  YEAR_CALL: "YEAR_CALL", // "Rozsah z ročného počtu telefón (A)"
  YEAR_PBX: "YEAR_PBX", // "Rozsah z ročného počtu AI (A)"
});
const defaultAutomaticConfig = {
  summaryOrAutomatic: true,
  operationType: Operation.NONE,
  frequencyDisabled: true,
  frequencyMin: 0,
  frequencyMax: 1,
  frequencyDefault: 0,
  minValueDisabled: true,
  minValueMin: 0,
  minValueMax: 1,
  minValueDefault: 0,
  maxValueDisabled: true,
  maxValueMin: 0,
  maxValueMax: 1,
  maxValueDefault: 0,
};
const defaultSummaryConfig = {
  summaryOrAutomatic: false,
  operationType: Operation.NONE,
  frequencyDisabled: true,
  frequencyMin: 0,
  frequencyMax: 1,
  frequencyDefault: 0,
  minValueDisabled: true,
  minValueMin: 0,
  minValueMax: 1,
  minValueDefault: 0,
  maxValueDisabled: true,
  maxValueMin: 0,
  maxValueMax: 1,
  maxValueDefault: 0,
};
const hoursConfig = {
  summaryOrAutomatic: true,
  operationType: Operation.ONE_OFF,
  frequencyDisabled: true,
  frequencyMin: 0,
  frequencyMax: 100,
  frequencyDefault: 0,
  minValueDisabled: false,
  minValueMin: 0,
  minValueMax: 23,
  minValueDefault: 0,
  maxValueDisabled: false,
  maxValueMin: 0,
  maxValueMax: 23,
  maxValueDefault: 0,
};
const localityConfig = {
  summaryOrAutomatic: true,
  operationType: Operation.ONE_OFF,
  frequencyDisabled: true,
  frequencyMin: 0,
  frequencyMax: 100,
  frequencyDefault: 0,
  minValueDisabled: false,
  minValueMin: 1,
  minValueMax: 9999,
  minValueDefault: 0,
  maxValueDisabled: true,
  maxValueMin: 0,
  maxValueMax: 0,
  maxValueDefault: 0,
};
const cycleConfig = {
  summaryOrAutomatic: true,
  operationType: Operation.ONE_OFF,
  frequencyDisabled: false,
  frequencyMin: 0,
  frequencyMax: 100,
  frequencyDefault: 0,
  minValueDisabled: true,
  minValueMin: 0,
  minValueMax: 0,
  minValueDefault: 0,
  maxValueDisabled: true,
  maxValueMin: 0,
  maxValueMax: 0,
  maxValueDefault: 0,
};
const counterConfig = {
  summaryOrAutomatic: true,
  operationType: Operation.ONE_OFF,
  frequencyDisabled: true,
  frequencyMin: 0,
  frequencyMax: 100,
  frequencyDefault: 0,
  minValueDisabled: false,
  minValueMin: 0,
  minValueMax: 100,
  minValueDefault: 0,
  maxValueDisabled: false,
  maxValueMin: 1,
  maxValueMax: 100,
  maxValueDefault: 0,
};
export const PriceExtraConfig = {
  PRICE_WAITING: { ...defaultSummaryConfig, icon: "home-clock-outline", color: "red" },
  PRICE_JOURNEY: { ...defaultSummaryConfig, icon: "road-variant", color: "red" },
  NONE: { ...defaultSummaryConfig, icon: "help-circle-outline", color: "blue" },
  GENERAL: { ...defaultSummaryConfig, icon: "vanish", color: "blue-grey darken-2" },
  ALWAYS: { ...defaultAutomaticConfig, icon: "all-inclusive", color: "blue-grey darken-2" },
  TYPE_INSTANT: { ...defaultAutomaticConfig, icon: "timer-alert-outline", color: "purple darken-1" },
  TYPE_TIME: { ...defaultAutomaticConfig, icon: "calendar-clock", color: "purple darken-1" },
  TYPE_STREET: { ...defaultAutomaticConfig, icon: "timer-marker-outline", color: "purple darken-1" },
  //TYPE_DIRECT: { ...defaultAutomaticConfig, icon: "hand-coin-outline", color: "purple darken-1" },
  //TYPE_REPEATED: { ...defaultAutomaticConfig, icon: "timer-sync-outline", color: "purple darken-1" },
  //TYPE_AIRPORT: { ...defaultAutomaticConfig, icon: "airplane-clock", color: "purple darken-1" },
  WAITING: { ...defaultAutomaticConfig, icon: "timer-sand", color: "red darken-4" },
  PREORDER: { ...defaultSummaryConfig, icon: "cart-plus" },
  NIGHT: { ...defaultSummaryConfig, icon: "weather-night" },
  WEEKEND: { ...defaultSummaryConfig, icon: "calendar-weekend-outline" },
  OFF_TIME: { ...defaultSummaryConfig, icon: "calendar-range" },
  AIRPORT_TRANSPORT: { ...defaultSummaryConfig, icon: "plane-car" },
  STATION_TRANSPORT: { ...defaultSummaryConfig, icon: "bus-stop" },
  ABROAD_TRANSPORT: { ...defaultSummaryConfig, icon: "sign-text" },
  PICKUP: { ...defaultSummaryConfig, icon: "map-marker-outline" },
  PICKUP_CITY: { ...defaultSummaryConfig, icon: "city-variant-outline" },
  PICKUP_OUT_CITY: { ...defaultSummaryConfig, icon: "landslide-outline" },
  DRINK_TRANSPORT: { ...defaultSummaryConfig, icon: "glass-mug-variant" },
  LUGGAGE_SMALL: { ...defaultAutomaticConfig, icon: "bag-checked", color: "orange darken-1" },
  LUGGAGE_MEDIUM: { ...defaultAutomaticConfig, icon: "bag-checked", color: "orange darken-1" },
  LUGGAGE_BIG: { ...defaultAutomaticConfig, icon: "bag-checked", color: "orange darken-1" },
  PAYMENT_TERMINAL: { ...defaultAutomaticConfig, icon: "credit-card-check-outline", color: "orange darken-2" },
  SPECIAL_TRANSPORT_OLD: { ...defaultAutomaticConfig, icon: "human-cane", color: "orange darken-2" },
  SPECIAL_TRANSPORT_CHAIR: { ...defaultAutomaticConfig, icon: "wheelchair", color: "orange darken-2" },
  AIRCONDITION: { ...defaultSummaryConfig, icon: "air-conditioner" },
  BOOSTER: { ...defaultAutomaticConfig, icon: "car-child-seat", color: "orange darken-2" },
  SEAT: { ...defaultAutomaticConfig, icon: "car-child-seat", color: "orange darken-2" },
  ANIMAL_SMALL: { ...defaultAutomaticConfig, icon: "dog-side", color: "orange darken-3" },
  ANIMAL_MEDIUM: { ...defaultAutomaticConfig, icon: "dog-side", color: "orange darken-3" },
  ANIMAL_BIG: { ...defaultAutomaticConfig, icon: "dog-side", color: "orange darken-3" },
  PREFERRED_DRIVER: { ...defaultAutomaticConfig, icon: "card-account-details-star", color: "deep-orange darken-1" },
  FOULING: { ...defaultSummaryConfig, icon: "liquid-spot" },
  STAINING: { ...defaultSummaryConfig, icon: "liquid-spot" },
  //VEHICLE_SEDAN: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_COMBI: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_CABRIO: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_COUPE: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_HATCHBACK: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_VAN: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_MICROBUS: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_BUS: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_VAN_CARGO: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_PICKUP: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //VEHICLE_SUV: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  PERSONS_2: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_3: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_4: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_5: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_6: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_7: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  PERSONS_8: { ...defaultAutomaticConfig, icon: "account-group", color: "blue darken-2" },
  SERVICE_PERSONAL: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_LIMO: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_CARGO: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_DRINK: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_IMMOBILE: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_COURIER: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_DELIVERY: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_AIRPORT: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //SERVICE_SOCIAL: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  PAYMENT_CASH: { ...defaultAutomaticConfig, icon: "cash-multiple", color: "green darken-2" },
  PAYMENT_CARD: { ...defaultAutomaticConfig, icon: "credit-card-outline", color: "green darken-2" },
  //PAYMENT_VIAMO: { ...defaultAutomaticConfig, icon: "hand-coin-outline", color: "green darken-2" },
  //PAYMENT_COUPON: { ...defaultAutomaticConfig, icon: "wallet-giftcard", color: "green darken-2" },
  PAYMENT_CARD_ONLINE: { ...defaultAutomaticConfig, icon: "credit-card-fast-outline", color: "green darken-2" },
  //PAYMENT_PREPAID: { ...defaultAutomaticConfig, icon: "credit-card-chip-outline", color: "green darken-2" },
  PAYMENT_POSTPAID: { ...defaultAutomaticConfig, icon: "invoice-list-outline", color: "green darken-2" },
  //PAYMENT_INVOICE: { ...defaultAutomaticConfig, icon: "hand-coin-outline", color: "green darken-2" },
  PICKUP_PLACE_CHANGED: { ...defaultSummaryConfig, icon: "hand-coin-outline" },
  DESTINATION_PLACE_CHANGED: { ...defaultSummaryConfig, icon: "hand-coin-outline" },
  WAYPOINT: { ...defaultAutomaticConfig, icon: "map-marker-radius-outline", color: "red" },
  WAYPOINT_STOP: { ...defaultSummaryConfig, icon: "map-marker-outline" },
  //WAYPOINT_INFO: { ...defaultAutomaticConfig, icon: "map-marker-outline" },
  WAYPOINT_DISTRICT: { ...defaultSummaryConfig, icon: "map-marker-outline" },
  WAYPOINT_CITY: { ...defaultSummaryConfig, icon: "map-marker-outline" },
  WAYPOINT_LOCALITY_PRICE: { ...localityConfig, icon: "map-marker-plus-outline" },
  BATTERY: { ...defaultSummaryConfig, icon: "car-battery" },
  FUEL: { ...defaultSummaryConfig, icon: "fuel" },
  DRAG_VEHICLE: { ...defaultSummaryConfig, icon: "car-arrow-right" },
  REGISTRATION: { ...defaultSummaryConfig, icon: "account-plus-outline" },
  BLOCKING: { ...defaultSummaryConfig, icon: "account-lock-outline" },
  UNBLOCKING: { ...defaultSummaryConfig, icon: "account-lock-open-outline" },
  SOURCE_DISPATCH_TAXISERVICE: { ...defaultAutomaticConfig, icon: "face-agent", color: "lime darken-4" },
  //SOURCE_DISPATCH_BROKER: { ...defaultAutomaticConfig, icon: "headset", color: "lime darken-4" },
  //SOURCE_DISPATCH_TAXXIMO: { ...defaultAutomaticConfig, icon: "headset", color: "lime darken-4" },
  SOURCE_DRIVER: { ...defaultAutomaticConfig, icon: "taxi", color: "lime darken-4" },
  SOURCE_APP: { ...defaultAutomaticConfig, icon: "cellphone-text", color: "lime darken-4" },
  SOURCE_BRANDAPP: { ...defaultAutomaticConfig, icon: "cellphone-key", color: "lime darken-4" },
  SOURCE_PBX: { ...defaultAutomaticConfig, icon: "phone-classic", color: "lime darken-4" },
  ACCOUNT: { ...defaultSummaryConfig, icon: "factory" },
  SHOPPING: { ...defaultSummaryConfig, icon: "basket-plus-outline" },
  //STORNO_PREREGISTERED: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_NEW: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_PLANNED: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_PENDING: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_ACCEPTED: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_WAITING: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_IN_PROGRESS: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  //STORNO_FINISHED: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  CUSTOMER_NEW: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_REGULAR: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_PRIORITY: { ...defaultAutomaticConfig, icon: "account-key-outline", color: "blue" },
  CUSTOMER_FAMOUS: { ...defaultAutomaticConfig, icon: "account-star-outline", color: "blue" },
  CUSTOMER_VIP: { ...defaultAutomaticConfig, icon: "account-heart-outline", color: "blue" },
  CUSTOMER_COLLEAGUE: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_STUDENT: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_CHILD: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_JUNIOR: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_SENIOR: { ...defaultAutomaticConfig, icon: "account-outline", color: "blue" },
  CUSTOMER_DEBTOR: { ...defaultAutomaticConfig, icon: "account-alert-outline", color: "blue" },
  CUSTOMER_BLOCKED: { ...defaultAutomaticConfig, icon: "account-cancel-outline", color: "blue" },
  HOURS: { ...hoursConfig, icon: "hours-24", color: "green darken-2" },
  MONDAY: { ...hoursConfig, icon: "table-clock", color: "green darken-2" },
  TUESDAY: { ...hoursConfig, icon: "table-clock", color: "green darken-2" },
  WEDNESDAY: { ...hoursConfig, icon: "table-clock", color: "green darken-2" },
  THURSDAY: { ...hoursConfig, icon: "table-clock", color: "green darken-2" },
  FRIDAY: { ...hoursConfig, icon: "table-clock", color: "green darken-2" },
  SATURDAY: { ...hoursConfig, icon: "calendar-clock", color: "green darken-2" },
  SUNDAY: { ...hoursConfig, icon: "calendar-clock", color: "green darken-2" },
  //RIDE_DISTANCE: { ...defaultAutomaticConfig, icon: "hand-coin-outline" },
  SOURCE_BRANDAPP_FIRST_TWO_ORDERS: { ...defaultAutomaticConfig, icon: "hand-coin-outline", color: "red" },
  SOURCE_BRANDAPP_EVERY_FIVE_ORDERS: { ...defaultAutomaticConfig, icon: "hand-coin-outline", color: "red" },
  //CYCLE_BRANDAPP: { ...cycleConfig, icon: "repeat-variant", color: "red" },
  //CYCLE_APP: { ...cycleConfig, icon: "repeat-variant", color: "red" },
  //CYCLE_CALL: { ...cycleConfig, icon: "repeat-variant", color: "red" },
  //CYCLE_PBX: { ...cycleConfig, icon: "repeat-variant", color: "red" },
  COUNTER_BRANDAPP: { ...counterConfig, icon: "cloud-percent-outline", color: "red" },
  COUNTER_APP: { ...counterConfig, icon: "cloud-percent-outline", color: "red" },
  COUNTER_CALL: { ...counterConfig, icon: "cloud-percent-outline", color: "red" },
  COUNTER_PBX: { ...counterConfig, icon: "cloud-percent-outline", color: "red" },
  MONTH_BRANDAPP: { ...counterConfig, icon: "ticket-percent-outline", color: "red" },
  MONTH_APP: { ...counterConfig, icon: "ticket-percent-outline", color: "red" },
  MONTH_CALL: { ...counterConfig, icon: "ticket-percent-outline", color: "red" },
  MONTH_PBX: { ...counterConfig, icon: "ticket-percent-outline", color: "red" },
  QUARTER_BRANDAPP: { ...counterConfig, icon: "percent-circle-outline", color: "red" },
  QUARTER_APP: { ...counterConfig, icon: "percent-circle-outline", color: "red" },
  QUARTER_CALL: { ...counterConfig, icon: "percent-circle-outline", color: "red" },
  QUARTER_PBX: { ...counterConfig, icon: "percent-circle-outline", color: "red" },
  HALFYEAR_BRANDAPP: { ...counterConfig, icon: "percent-box-outline", color: "red" },
  HALFYEAR_APP: { ...counterConfig, icon: "percent-box-outline", color: "red" },
  HALFYEAR_CALL: { ...counterConfig, icon: "percent-box-outline", color: "red" },
  HALFYEAR_PBX: { ...counterConfig, icon: "percent-box-outline", color: "red" },
  YEAR_BRANDAPP: { ...counterConfig, icon: "sale-outline", color: "red" },
  YEAR_APP: { ...counterConfig, icon: "sale-outline", color: "red" },
  YEAR_CALL: { ...counterConfig, icon: "sale-outline", color: "red" },
  YEAR_PBX: { ...counterConfig, icon: "sale-outline", color: "red" },
};