<template>
  <div v-if="item">
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Pricelist.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Help button to open helpDialog -->
      <v-btn icon @click="helpDialog = true" class="mr-4">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-icon x-large>fa-money-bill</v-icon>
      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title center-active show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1">{{ $t("Pricelist.tabBasic") }}</v-tab>
          <v-tab key="tab2">{{ $t("Pricelist.tabPriceSettings") }}</v-tab>
          <v-tab key="tab3">{{ $t("Pricelist.tabPrices") }}</v-tab>
          <v-tab key="tab4">{{ $t("Pricelist.tabExtras") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs" touchless>
      <!-- TAB1 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                small
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                small
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn
                color="success"
                small
                v-show="editMode"
                @click="submitForm()"
                >{{ $t("btnSave") }}</v-btn
              >
            </div>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.id"
                  :title="$t('Pricelist.id')"
                  :label="$t('Pricelist.id')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.name"
                  :title="$t('Pricelist.name')"
                  :aria-labelledby="$t('Pricelist.name')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="item.note"
                  :title="$t('Pricelist.note')"
                  :label="$t('Pricelist.note')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="item.active"
                  :title="$t('Pricelist.active')"
                  :label="$t('Pricelist.active')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <my-datepicker
                  v-model="item.validFrom"
                  :title="$t('Pricelist.validFrom')"
                  :label="$t('Pricelist.validFrom')"
                  filled
                  :disabled="!editMode"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.service"
                  :title="$t('Pricelist.service')"
                  :label="$t('Pricelist.service')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="$t('PricelistEnums.ServiceArray')"
                  required
                  filled
                ></v-select>
              </v-col>
              <!--  <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.type"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  label="Type"
                  title="Type"
                  :disabled="!editMode"
                  :items="Type"
                  required
                  filled
                ></v-select>
              </v-col> -->
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.showPrice"
                  :title="$t('Pricelist.showPrice')"
                  :label="$t('Pricelist.showPrice')"
                  :readonly="readOnlyMainSettings"
                  :disabled="!editMode || readOnlyMainSettings"
                  :items="$t('PricelistEnums.showPriceArray')"
                  required
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.currency"
                  :title="$t('Pricelist.currency')"
                  :label="$t('Pricelist.currency')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.currencySymbol"
                  :title="$t('Pricelist.currencySymbol')"
                  :label="$t('Pricelist.currencySymbol')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.priceRound"
                  :title="$t('Pricelist.priceRound')"
                  :label="$t('Pricelist.priceRound')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <!-- TAB2 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                small
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                small
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn
                color="success"
                small
                v-show="editMode"
                @click="submitForm()"
                >{{ $t("btnSave") }}</v-btn
              >
            </div>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.fareType"
                  title="Fare type"
                  :label="$t('Pricelist.fareType')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="FareType"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.fareChangeToDefault"
                  title="fareChangeToDefault"
                  :label="$t('Pricelist.fareChangeToDefault')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="FareChangeToDefault"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="item.fixation"
                  title="Fixation"
                  :label="$t('Pricelist.fixation')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  inset
                  v-model="item.waypointInProgressRecalcPrice"
                  title="waypointInProgressRecalcPrice"
                  :label="$t('Pricelist.waypointInProgressRecalcPrice')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Pricelist.fareStartSubheader") }}</v-subheader>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.fareStartType"
                  title="fareStartType"
                  :label="$t('Pricelist.fareStartType')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="FareStartType"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareStart"
                  title="fareStart"
                  :label="$t('Pricelist.fareStart')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Pricelist.fareWaitingSubheader") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareWaitingLimit"
                  title="fareWaitingLimit"
                  :label="$t('Pricelist.fareWaitingLimit')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareWaiting"
                  title="fareWaiting"
                  :label="$t('Pricelist.fareWaiting')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-subheader>{{ $t("Pricelist.fareDriveSubheader") }}</v-subheader>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.fareDriveType"
                  title="fareDriveType"
                  :label="$t('Pricelist.fareDriveType')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="FareDriveType"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareDriveDistance"
                  title="fareDriveDistance"
                  :label="$t('Pricelist.fareDriveDistance')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field> </v-col
              ><v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareDriveTime"
                  title="fareDriveTime"
                  :label="$t('Pricelist.fareDriveTime')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{
              $t("Pricelist.fareJourneyMinimumSubheader")
            }}</v-subheader>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  v-model="item.fareJourneyMinimumType"
                  title="fareJourneyMinimumType"
                  :label="$t('Pricelist.fareJourneyMinimumType')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :disabled="!editMode"
                  :items="FareJourneyMinimumType"
                  required
                  filled
                ></v-select> </v-col
              ><v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="item.fareJourneyMinimum"
                  title="fareJourneyMinimum"
                  :label="$t('Pricelist.fareJourneyMinimum')"
                  type="number"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field> </v-col
            ></v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB3 PRICES -->
        <v-card flat>
          <prices :pricelistId="itemId" />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB4 PRICE EXTRA -->
        <v-card flat>
          <price-extra-list :pricelistId="itemId" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- HELP text -->
    <v-dialog
      v-model="helpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="helpDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Help</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <h2>Aktívny cenník</h2>
                <p>
                  Aktívny cenník je cenník, ktorý sa zaškrtnuté "Aktívny/Active"
                  a má dátum platnosti (Valid from) v minulosti. Ak je viac
                  aktívnych cenníkov, tak sa použije ten, ktorý má najnovší
                  (neskorší) dátum platnosti.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2>Service</h2>
                <p>Odporúčané je nastavenie Personal</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2>Type</h2>
                <p>
                  Type označuje, komu sa bude vypočítaná cena na základe tohto
                  cenníka zobrazovať: <br />- ALL <br />- DRIVER <br />- HIDDEN
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2>Čakanie na zákazníka pred začatím prepravy</h2>
                <p>
                  Čakanie na zákazníka pred začatím prepravy je čas, ktorý
                  zákazník čaká na prepravu. Ak je čas čakania na zákazníka
                  menší ako je nastavený limit, tak sa za čakanie neúčtuje
                  žiadna sadzba. Ak je čas čakania na zákazníka väčší ako je
                  nastavený limit, tak sa za čakanie účtuje sadzba za čakanie
                  (FareWaiting).
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItem()"> {{ $t("btnReload") }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

import {
  Type,
  Service,
  FareType,
  FareChangeToDefault,
  FareStartType,
  FareDriveType,
  FareJourneyMinimumType,
  ShowPrice,
} from "../enums/PricelistEnum";
import Prices from "../components/Prices.vue";
import PriceExtraList from "../components/PriceExtraList.vue";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  components: {
    Prices,
    PriceExtraList,
  },
  data() {
    return {
      tabs: null,
      helpDialog: false,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      pricelistType: "SIMPLE",
      //enums
      Type: Object.values(Type),
      //Service: Object.values(Service),
      Service: this.$t("PricelistEnums.ServiceArray"),
      //FareType: Object.keys(FareType),
      FareType: this.$t("PricelistEnums.FareTypeArray"),
      //FareChangeToDefault: Object.keys(FareChangeToDefault),
      FareChangeToDefault: this.$t("PricelistEnums.FareChangeToDefaultArray"),
      //FareStartType: Object.keys(FareStartType),
      FareStartType: this.$t("PricelistEnums.FareStartTypeArray"),
      //FareDriveType: Object.keys(FareDriveType),
      FareDriveType: this.$t("PricelistEnums.FareDriveTypeArray"),
      //FareJourneyMinimumType: Object.keys(FareJourneyMinimumType),
      FareJourneyMinimumType: this.$t(
        "PricelistEnums.FareJourneyMinimumTypeArray"
      ),
      //ShowPrice: Object.keys(ShowPrice),
      ShowPrice: this.$t("PricelistEnums.ShowPriceArray"),

      menu2: false,
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      editMode: false,
      itemId: null,
      item: null,
      cachedItem: null,
      readOnlyMainSettings: false,
    };
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
      this.pricelistType = modules.pricelistType;
    }
  },
  mounted() {
    //get id from url as number
    this.itemId = Number(this.$route.params.id);
    this.getItem();
    //Zablokovanie zakladnych poli proti editovaniu pre pouzivatelov taxisluzby. Pre taxximo poutivatelov je povolene
    let userRoles = this.$store.state.user.roleList;
    let enabledRoles = ["ADMIN", "SUPPORT"];
    this.readOnlyMainSettings =
      userRoles.some((a) => (enabledRoles.includes(a) ? false : true)) == true
        ? true
        : false;
  },
  methods: {
    enableEditMode(enabled) {
      this.editMode = enabled;
    },
    getItem() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/pricelist/${this.itemId}`
        )
        .then((response) => {
          this.item = response.data;
          this.cachedItem = Object.assign({}, this.item);
          // console.log(
          //   moment(this.item.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
          // );
          if (this.pricelistType === "SIMPLE") {
            // remove all types from FareType but "DEFAULT"
            this.FareType = this.FareType.filter((x) => x.value === "DEFAULT");
            //this.FareType = this.FareType.filter((x) => x === "DEFAULT");
            // remove all types from FareChangeToDefault but "NONE"
            this.FareChangeToDefault = this.FareChangeToDefault.filter(
              (x) => x.value === "NONE"
            );
            this.FareJourneyMinimumType = this.FareJourneyMinimumType.filter(
              (x) => x.value === "DEFAULT"
            );
          }
          //Cena vyzdvihnutia s vypoctom za trasu od/k stanovistu je iba pre pricelistType "EXTENDED"
          if (
            this.pricelistType === "SIMPLE" ||
            this.pricelistType === "LOCALITY"
          ) {
            // remove all types from FareStartType but "FIXED"
            this.FareStartType = this.FareStartType.filter(
              (x) => x.value === "FIXED"
            );
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    reset() {
      this.enableEditMode(false);
      this.$refs.form.resetValidation();
      //this.$refs.form.reset();
      this.item = Object.assign({}, this.cachedItem);
    },

    submitForm() {
      console.log(this.item.validFrom);
      const isValid = this.$refs.form.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/pricelist`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.cachedItem = Object.assign({}, this.item);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
  },
};
</script>
