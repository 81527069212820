<template>
  <div v-if="item">
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Settings.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fas fa-city</v-icon>
      <template v-slot:extension>
        <v-tabs
          icons-and-text
          v-model="tabs"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1"
            >{{ $t("Settings.tabInfo") }}<v-icon small>fa-info</v-icon></v-tab
          >
          <v-tab key="tab2"
            >{{ $t("Settings.tabAutomat")
            }}<v-icon small>mdi-robot</v-icon></v-tab
          >
          <v-tab key="tab3"
            >{{ $t("Settings.tabSettings")
            }}<v-icon small>fa-cog</v-icon></v-tab
          >
          <v-tab key="tab4"
            >{{ $t("Settings.tabContacts")
            }}<v-icon small>fa-phone</v-icon></v-tab
          ><v-tab key="tab5"
            >{{ $t("Settings.tabGateway") }}
            <v-icon small>mdi-phone-classic</v-icon></v-tab
          >
          <v-tab key="tab6"
            >{{ $t("Settings.tabOrderTypes")
            }}<v-icon small>mdi-road-variant</v-icon></v-tab
          >
          <v-tab key="tab7"
            >{{ $t("Settings.tabPaymentOptions")
            }}<v-icon small>fa-shopping-basket</v-icon></v-tab
          >
          <v-tab key="tab8"
            >{{ $t("Settings.TabSms")
            }}<v-icon small>mdi-message-cog-outline</v-icon></v-tab
          >
          <v-tab key="tab9"
            >{{ $t("Settings.TabModules")
            }}<v-icon small>mdi-view-module</v-icon></v-tab
          >
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs" touchless>
      <!-- TAB1 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-row v-show="!readOnlyMainSettings">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.id"
                  :label="$t('Settings.id')"
                  disabled
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.brokerId"
                  :label="$t('Settings.brokerId')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12" md="3">
                <v-text-field
                  v-model="item.name"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.name')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.company"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.company')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.country"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.country')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Country)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.city"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.city')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.taxiLicenceNumber"
                  :label="$t('Settings.taxiLicenceNumber')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <my-datepicker
                  v-model="item.taxiLicenceExpiration"
                  :title="$t('Settings.taxiLicenceExpiration')"
                  :label="$t('Settings.taxiLicenceExpiration')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  filled
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.type"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.type')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Type)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.status"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.status')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  :items="Object.keys(Status)"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.descriptionShort"
                  :label="$t('Settings.descriptionShort')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="item.description"
                  :label="$t('Settings.description')"
                  :disabled="!editMode"
                  filled
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB2 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>
              <b>{{
                $t("Settings.subheaderActivationTimeOrder")
              }}</b> </v-subheader
            ><v-row
              ><v-col cols="12">
                <p>
                  {{ $t("Settings.activationTimeOrderDescription") }}
                </p></v-col
              ></v-row
            >
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.defaultPresendTime"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.defaultPresendTime')"
                  :title="$t('Settings.defaultPresendTimeTitle')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="min"
                  :hint="$t('Settings.defaultPresendTime_hint')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  :disabled="!editMode"
                  :label="$t('Settings.automat')"
                  inset
                  required
                  v-model="item.automat"
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider>
            <v-subheader
              ><b>
                {{ $t("Settings.subheaderDistanceAutomat") }}</b
              ></v-subheader
            >
            <v-row> -->
            <!-- Toto uplne vypustit <v-col cols="12" md="3">
                <v-select
                  v-model="item.directSendType"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  label="Direct send type"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(DirectSendType)"
                  filled
                ></v-select>
              </v-col> -->
            <!--<v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radiusDirect"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radiusDirect')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.delay"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.delay')"
                  :title="$t('Settings.delayTitle')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>-->
            <v-divider></v-divider>
            <v-subheader>
              <b>{{ $t("Settings.subheaderImmediateAutomat") }}</b></v-subheader
            ><v-row>
              <v-col cols="12"
                ><p>{{ $t("Settings.immediateAutomatDescription") }}</p></v-col
              ></v-row
            >
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.immediateAutomat"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.immediateAutomat')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.ImmediateAutomatArray')"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>
              <b>{{ $t("Settings.subheaderPriorityAutomat") }}</b></v-subheader
            ><v-row>
              <v-col cols="12"
                ><p>{{ $t("Settings.priorityAutomatDescription") }}</p></v-col
              ></v-row
            >
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.activeOrderBeforeSend"
                  :label="$t('Settings.activeOrderBeforeSend')"
                  :title="$t('Settings.activeOrderBeforeSendTitle')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.priorityZero"
                  :label="$t('Settings.priorityZero')"
                  :title="$t('Settings.priorityZeroTitle')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius1')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.radius1_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.automatPriorityTime1')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="min"
                  :hint="$t('Settings.automatPriorityTime1_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius2"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius2')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.radius2_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius3"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius3')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.radius3_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime2"
                  :rules="[
                    validateAutomatPriorityTime2,
                    (v) => !!v || $t('msgRequiredField'),
                  ]"
                  :label="$t('Settings.automatPriorityTime2')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="min"
                  :hint="$t('Settings.automatPriorityTime2_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius4"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius4')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.radius4_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatPriorityTime3"
                  :rules="[
                    validateAutomatPriorityTime3,
                    (v) => v >= 1 || 'Min should not be below 1min',
                    (v) => !!v || $t('msgRequiredField'),
                  ]"
                  :label="$t('Settings.automatPriorityTime3')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="min"
                  :hint="$t('Settings.automatPriorityTime3_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.radius5"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.radius5')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.radius5_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12"
                ><p>
                  {{ $t("Settings.automatBonusMaxMinuteDescription") }}
                </p></v-col
              ></v-row
            >
            <v-row>
              <v-col cols="12" md="3">
                <!-- minimal value 1 -->
                <v-text-field
                  type="number"
                  v-model="item.automatBonusMaxMinute"
                  :rules="[
                    (v) => !!v || $t('msgRequiredField'),
                    (v) => v >= 1 || 'Min should not be below 1min',
                    (v) => v <= 120 || 'Max should not be above 120min',
                  ]"
                  :label="$t('Settings.automatBonusMaxMinute')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="min"
                  :hint="$t('Settings.automatBonusMaxMinute_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.automatBonusMaxRadius"
                  :rules="[
                    (v) => !!v || $t('msgRequiredField'),
                    (v) => v >= 0 || 'Min should not be below 0 meters',
                    (v) => v <= 1000 || 'Max should not be above 1000meters',
                  ]"
                  :label="$t('Settings.automatBonusMaxRadius')"
                  :disabled="!editMode"
                  required
                  filled
                  suffix="m"
                  :hint="$t('Settings.automatBonusMaxRadius_hint')"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB3 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderDriverApp") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowSilence"
                  :label="$t('Settings.allowSilence')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.allowSilenceTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowStreetOrder"
                  :label="$t('Settings.allowStreetOrder')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.allowStreetOrderTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.showRouteInfoNotif"
                  :label="$t('Settings.showRouteInfoNotif')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.showRouteInfoNotifTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.showClientMarkInNotif"
                  :label="$t('Settings.showClientMarkInNotif')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.showClientMarkInNotifTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.showClientNicknameInNotif"
                  :label="$t('Settings.showClientNicknameInNotif')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.showClientNicknameInNotifTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.showFiltersInNotif"
                  :label="$t('Settings.showFiltersInNotif')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.showFiltersInNotifTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.allowOrderCancel"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.allowOrderCancel')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.AllowOrderCancelArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.reorderOrders"
                  :label="$t('Settings.reorderOrders')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.reorderOrders')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <!-- <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.popupTime"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.popupTime')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.popupTimeTitle')"
                ></v-text-field>
              </v-col> -->
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderWaiting") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.allowWaiting"
                  :label="$t('Settings.allowWaiting')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.waitingAfterStartLimit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.waitingAfterStartLimit')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.waitingAfterStartLimitTitle')"
                  suffix="s"
                  :hint="$t('Settings.waitingAfterStartLimit_hint')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.waitingAfterLastLimit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.waitingAfterLastLimit')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.waitingAfterLastLimitTitle')"
                  suffix="s"
                  :hint="$t('Settings.waitingAfterLastLimit_hint')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderWorkshift") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.vehicleAllocation"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.vehicleAllocation')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.VehicleAllocationTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.approvalWorklog"
                  :label="$t('Settings.approvalWorklog')"
                  :disabled="!editMode"
                  :readonly="!module.worklog"
                  required
                  filled
                  :title="$t('Settings.approvalWorklogTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  v-model="item.validateMileages"
                  label="Validácia kilometrov"
                  inset
                  :disabled="!editMode"
                  :readonly="!module.worklog"
                  required
                  filled
                  :title="$t('Settings.validateMileagesTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderPbxCallRouting") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.pbxCallRouting"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.pbxCallRouting')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.PbxCallRoutingTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.driverPhoneExt"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.driverPhoneExt')"
                  :title="$t('Settings.driverPhoneExtTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.DriverPhoneExtTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.driverPhone"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.driverPhone')"
                  :title="$t('Settings.driverPhoneTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.DriverPhoneTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.showDriversDriverMap"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.showDriversDriverMap')"
                  :title="$t('Settings.showDriversDriverMapTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.ShowDriversDriverMapTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.showDriverCalllog"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.showDriverCalllog')"
                  :title="$t('Settings.showDriverCalllogTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.ShowDriverCalllogTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.showStreetOrderCreateBtn"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.showStreetOrderCreateBtn')"
                  :title="$t('Settings.showStreetOrderCreateBtnTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.ShowStreetOrderCreateBtnTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.showDriverOrderList"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.showDriverOrderList')"
                  :title="$t('Settings.showDriverOrderListTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.showDriverOrderListTypeArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.showClientPhoneDriverDispatch"
                  :label="$t('Settings.showClientPhoneDriverDispatch')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.showClientPhoneDriverDispatchTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
            </v-row>
            <v-divider light class="mb-6"></v-divider>
            <v-subheader>{{ $t("Settings.subheaderOther") }}</v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.smsCancellation"
                  :label="$t('Settings.smsCancellation')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.smsCancellationTitle')"
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.realDistanceCorrection"
                  :rules="[
                    (v) => !!v || $t('msgRequiredField'),
                    (v) =>
                      (v >= -15 && v <= 15) ||
                      $t('Settings.msgRealDistanceCorrectionValueOutOfRange'),
                    (v) =>
                      /^-?\d+(\.\d{1})?$/.test(v) ||
                      $t('Settings.msgOneDecimalAllowed'),
                  ]"
                  :label="$t('Settings.realDistanceCorrection')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.realDistanceCorrectionTitle')"
                  suffix="%"
                  :hint="$t('Settings.realDistanceCorrection_hint')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.localityCheck"
                  :label="$t('Settings.localityCheck')"
                  :disabled="!editMode"
                  :readonly="readOnlyMainSettings"
                  required
                  filled
                  class="ml-3"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.driverAvailability"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.driverAvailability')"
                  :title="$t('Settings.driverAvailabilityTitle')"
                  :disabled="!editMode"
                  required
                  :items="$t('Settings.DriverAvailabilityArray')"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <!--<v-divider light class="mb-6"></v-divider>
            <v-subheader>{{ $t("Settings.subheaderPayment") }}</v-subheader>
            <v-row>
               <v-col cols="12" md="3">
                <v-select
                  v-model="item.receiptEdit"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  label="Úprava účtenky"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(ReceiptEdit)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.paymentProfileId"
                  :label="$t('Settings.paymentProfileId')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.profileId"
                  :label="$t('Settings.profileId')"
                  :disabled="!editMode"
                  required
                  filled
                  :title="$t('Settings.profileIdTitle')"
                ></v-text-field>
              </v-col>
            </v-row>-->
            <!--<v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderLocalityCheck") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-switch
                  inset
                  v-model="item.standCheck"
                  :label="$t('Settings.standCheck')"
                  :disabled="!editMode"
                  required
                  filled
                  class="ml-3"
                  readonly
                ></v-switch>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB4 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderContacts") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.email"
                  :label="$t('Settings.email')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.emailTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.problemEmail"
                  :label="$t('Settings.problemEmail')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.problemEmailTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.problemPhone"
                  :label="$t('Settings.problemPhone')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.problemPhoneTitle')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.maintenanceVehicleEmail"
                  :label="$t('Settings.maintenanceVehicleEmail')"
                  :disabled="!editMode"
                  filled
                  :title="$t('Settings.maintenanceVehicleEmailTitle')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderDispatchPhones") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone1"
                  :label="$t('Settings.phone1')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone2"
                  :label="$t('Settings.phone2')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone3"
                  :label="$t('Settings.phone3')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone4"
                  :label="$t('Settings.phone4')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone5"
                  :label="$t('Settings.phone5')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone6"
                  :label="$t('Settings.phone6')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone7"
                  :label="$t('Settings.phone7')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone8"
                  :label="$t('Settings.phone8')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone9"
                  :label="$t('Settings.phone9')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone10"
                  :label="$t('Settings.phone10')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone11"
                  :label="$t('Settings.phone11')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone12"
                  :label="$t('Settings.phone12')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB5 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                :disabled="disabledAllSettings"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>

            <v-divider></v-divider>
            <v-subheader>{{ $t("Settings.subheaderSmsGateway") }} </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.smsGate"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.smsGate')"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(SmsGateway)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsIdentity"
                  :label="$t('Settings.smsIdentity')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsLogin"
                  :label="$t('Settings.smsLogin')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.smsPassword"
                  :label="$t('Settings.smsPassword')"
                  :disabled="!editMode"
                  filled
                  :append-icon="showSmsPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showSmsPassword ? 'text' : 'password'"
                  @click:append="showSmsPassword = !showSmsPassword"
                  autocomplete="new-password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderPhoneGateway") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.phoneGate"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Settings.phoneGate')"
                  :disabled="!editMode"
                  required
                  :items="Object.keys(PhoneGateway)"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneGateLogin"
                  :label="$t('Settings.phoneGateLogin')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneGatePassword"
                  :label="$t('Settings.phoneGatePassword')"
                  :disabled="!editMode"
                  filled
                  :append-icon="showPhonePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPhonePassword ? 'text' : 'password'"
                  @click:append="showPhonePassword = !showPhonePassword"
                  autocomplete="new-password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader
              >{{ $t("Settings.subheaderPhoneExtensions") }}
            </v-subheader>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt1"
                  :label="$t('Settings.phoneExt1')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt2"
                  :label="$t('Settings.phoneExt2')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt3"
                  :label="$t('Settings.phoneExt3')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt4"
                  :label="$t('Settings.phoneExt4')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt5"
                  :label="$t('Settings.phoneExt5')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt6"
                  :label="$t('Settings.phoneExt6')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt7"
                  :label="$t('Settings.phoneExt7')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt8"
                  :label="$t('Settings.phoneExt8')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt9"
                  :label="$t('Settings.phoneExt9')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt10"
                  :label="$t('Settings.phoneExt10')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt11"
                  :label="$t('Settings.phoneExt11')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt12"
                  :label="$t('Settings.phoneExt12')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <v-tab-item eager>
        <!-- TAB6 ORDER TYPES -->
        <v-card flat>
          <v-btn
            class="my-2"
            color="success"
            @click.stop="createSupportOrderType()"
            :disabled="disabledAllSettings"
          >
            {{ $t("btnCreate") }}
          </v-btn>
          <v-data-table
            :loading="loadingDataSupportOrderTypes"
            :headers="gridHeadersSupportOrderTypes"
            :items="gridItemsSupportOrderTypes"
            :items-per-page="40"
            class="elevation-1"
            sort-by="type"
          >
            <template v-slot:no-data>
              <v-btn color="error" @click="getItemsSupportOrderTypes()">
                {{ $t("btnReload") }}</v-btn
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="editSupportOrderType(item.id)"
                >mdi-square-edit-outline</v-icon
              >
              <v-icon
                class="mx-2"
                small
                @click="deleteSupportOrderType(item.id)"
                >mdi-delete</v-icon
              >
            </template>

            <template v-slot:[`item.type`]="{ item }">
              {{ $t("orderType." + item.type) }}
            </template>
            <template v-slot:[`item.accept`]="{ item }">
              {{ $t("Settings.AcceptSupportOrderType." + item.accept) }}
            </template>

            <template v-slot:[`item.send`]="{ item }">
              {{ $t("Settings.SendSupportOrderType." + item.send) }}
            </template>

            <template v-slot:[`item.sendType`]="{ item }">
              {{ $t("Settings.SendtypeSupportOrderType." + item.sendType) }}
            </template>

            <template v-slot:[`item.moveAutomat`]="{ item }">
              <v-simple-checkbox
                v-model="item.moveAutomat"
                disabled
              ></v-simple-checkbox>
            </template>
          </v-data-table>
          <v-dialog
            v-model="editSupportOrderDialog"
            transition="dialog-top-transition"
            max-width="600px"
          >
            <v-toolbar color="secondary" dark flat>
              <v-toolbar-title>{{
                $t("Settings.dialogEditSuportOrderTitle")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
              <v-form ref="formEditSupport" v-model="formEditSupportValidation">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!--<v-col cols="12">
                        <v-text-field
                          label="ID"
                          filled
                          v-model="editItemSupportOrderTypes.id"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          disabled
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12">
                        <v-select
                          v-model="editItemSupportOrderTypes.type"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderType')"
                          :items="$t('orderTypeArray')"
                          required
                          filled
                          disabled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <!-- :items="Object.keys(AcceptSupportOrderType)" -->
                        <v-select
                          v-model="editItemSupportOrderTypes.accept"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderAccept')"
                          :items="$t('Settings.AcceptSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <!-- :items="Object.keys(SendSupportOrderType)" -->
                        <v-select
                          v-model="editItemSupportOrderTypes.send"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderSend')"
                          :items="$t('Settings.SendSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <!-- :items="Object.keys(SendtypeSupportOrderType)" -->
                        <v-select
                          v-model="editItemSupportOrderTypes.sendType"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderSendType')"
                          :items="$t('Settings.SendtypeSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox
                          dense
                          :label="$t('Settings.supportOrderMoveAutomat')"
                          filled
                          v-model="editItemSupportOrderTypes.moveAutomat"
                          required
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gray"
                    text
                    @click.stop="editSupportOrderDialog = false"
                  >
                    {{ $t("btnClose") }}</v-btn
                  >
                  <v-btn
                    color="success"
                    text
                    @click.stop="
                      (loadingEditSupport = true), submitEditSupportDialog()
                    "
                    :loading="loadingEditSupport"
                    :disabled="loadingEditSupport"
                  >
                    {{ $t("btnUpdate") }}</v-btn
                  >
                  <!--<v-btn
                    color="success"
                    text
                    @click.stop="
                      (loadingEditSupport = true),
                        submitEditSupportDialog('CREATE')
                    "
                    :loading="loadingEditSupport"
                    :disabled="loadingEditSupport"
                    >Create</v-btn
                  >
                  <v-btn
                    color="error"
                    text
                    @click.stop="
                      (loadingEditSupport = true),
                        submitEditSupportDialog('DELETE')
                    "
                    :loading="loadingEditSupport"
                    :disabled="loadingEditSupport"
                    >Delete</v-btn
                  >-->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="createSupportOrderDialog"
            transition="dialog-top-transition"
            max-width="600px"
          >
            <v-toolbar color="secondary" dark flat>
              <v-toolbar-title>{{
                $t("Settings.dialogCreateSuportOrderTitle")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
              <v-form
                ref="formCreateSupport"
                v-model="formCreateSupportValidation"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="createItemSupportOrderTypes.type"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderType')"
                          :items="$t('orderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="createItemSupportOrderTypes.accept"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderAccept')"
                          :items="$t('Settings.AcceptSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="createItemSupportOrderTypes.send"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderSend')"
                          :items="$t('Settings.SendSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="createItemSupportOrderTypes.sendType"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderSendType')"
                          :items="$t('Settings.SendtypeSupportOrderTypeArray')"
                          required
                          filled
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox
                          dense
                          :label="$t('Settings.supportOrderMoveAutomat')"
                          filled
                          v-model="createItemSupportOrderTypes.moveAutomat"
                          required
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gray"
                    text
                    @click.stop="createSupportOrderDialog = false"
                  >
                    {{ $t("btnClose") }}</v-btn
                  >
                  <v-btn
                    color="success"
                    text
                    @click.stop="
                      (loadingCreateSupport = true), submitCreateSupportDialog()
                    "
                    :loading="loadingCreateSupport"
                    :disabled="loadingCreateSupport"
                  >
                    {{ $t("btnCreate") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="deleteSupportOrderDialog"
            transition="dialog-top-transition"
            max-width="600px"
          >
            <v-toolbar color="secondary" dark flat>
              <v-toolbar-title>{{
                $t("Settings.dialogDeleteSuportOrderTitle")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
              <v-form
                ref="formDeleteSupport"
                v-model="formDeleteSupportValidation"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!--<v-col cols="12">
                        <v-text-field
                          label="ID"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          filled
                          v-model="deleteItemSupportOrderTypes.id"
                          disabled
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12">
                        <!-- :items="Object.keys(TypeSupportOrderType)" -->
                        <v-select
                          v-model="deleteItemSupportOrderTypes.type"
                          :rules="[(v) => !!v || $t('msgRequiredField')]"
                          :label="$t('Settings.supportOrderType')"
                          :items="$t('orderTypeArray')"
                          disabled
                          filled
                        ></v-select>
                      </v-col>
                      <div class="pa-4">
                        {{ $t("Settings.supportOrderDeleteWarning") }}
                      </div>
                      <v-col cols="12">
                        <v-subheader class="px-6"
                          >{{ $t("Settings.supportOrderdeleteSubheader") }}
                        </v-subheader>
                        <v-slider
                          v-model="deleteItemSupportOrderTypes.slider"
                          thumb-label
                          color="green"
                          track-color="red"
                          class="px-6"
                        >
                          <template v-slot:thumb-label="{ value }">
                            {{
                              sliderConfimationCheck[
                                Math.min(Math.floor(value / 100), 1)
                              ]
                            }}
                          </template></v-slider
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gray"
                    text
                    @click.stop="deleteSupportOrderDialog = false"
                  >
                    {{ $t("btnClose") }}</v-btn
                  >
                  <v-btn
                    color="error"
                    text
                    @click.stop="
                      (loadingDeleteSupport = true), submitDeleteSupportDialog()
                    "
                    :loading="loadingDeleteSupport"
                    :disabled="loadingDeleteSupport"
                  >
                    {{ $t("btnDelete") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB7 PAYMENT OPTIONS -->
        <v-card flat>
          <payment-option-list />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB8 SMS TEMPLATES -->
        <v-card flat>
          <sms-template-list />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- TAB9 MODULES -->
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("Settings.tableHeadersSupportModules.icon") }}
                </th>
                <th class="text-left">
                  {{ $t("Settings.tableHeadersSupportModules.name") }}
                </th>
                <th class="text-left">
                  {{ $t("Settings.tableHeadersSupportModules.desctription") }}
                </th>
                <th class="text-left">
                  {{ $t("Settings.tableHeadersSupportModules.status") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <v-icon> mdi-car-multiple </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.limitWorkshift")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\nlimitWorkshift" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.limitWorkshiftDescription"
                    )
                  }}
                </td>
                <td>{{ module.limitWorkshift }}</td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-credit-card-multiple-outline </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.cardPayments")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\ncardPayments" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.cardPaymentsDescription"
                    )
                  }}
                </td>
                <td>
                  <v-icon v-if="module.cardPayments" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>
              <tr>
                <td>
                  <v-icon> mdi-sale-outline </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.discount") }}</b>
                  {{ !readOnlyMainSettings ? "\ndiscount" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.discountDescription")
                  }}
                </td>
                <td>
                  <v-icon v-if="module.discount" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-chat-outline </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.dispatchingChat")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\ndispatchingChat" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.dispatchingChatDescription"
                    )
                  }}
                </td>
                <td>
                  <v-icon v-if="module.dispatchingChat" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-web </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.limitLocalityPrice")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\nlimitLocalityPrice" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.limitLocalityPriceDescription"
                    )
                  }}
                </td>
                <td>{{ module.limitLocalityPrice }}</td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-car-select </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.limitStand") }}</b>
                  {{ !readOnlyMainSettings ? "\nlimitStand" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.limitStandDescription")
                  }}
                </td>
                <td>{{ module.limitStand }}</td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-cash </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.pricelistType")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\npricelistType" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.pricelistTypeDescription"
                    )
                  }}
                </td>
                <td>{{ $t("ModulePricelistType." + module.pricelistType) }}</td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-finance </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.report") }}</b>
                  {{ !readOnlyMainSettings ? "\nreport" : "" }}
                </td>
                <td>
                  {{ $t("Settings.tableRowsSupportModules.reportDescription") }}
                </td>
                <td>
                  <v-icon v-if="module.report" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-car-clock </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.timeOrder") }}</b>
                  {{ !readOnlyMainSettings ? "\ntimeOrder" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.timeOrderDescription")
                  }}
                </td>
                <td>
                  <v-icon v-if="module.timeOrder" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-store </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.account") }}</b>
                  {{ !readOnlyMainSettings ? "\naccount" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.accountDescription")
                  }}
                </td>
                <td>
                  <v-icon v-if="module.account" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-car-connected </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.worklog") }}</b>
                  {{ !readOnlyMainSettings ? "\nworklog" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.worklogDescription")
                  }}
                </td>
                <td>
                  <v-icon v-if="module.worklog" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-map-search-outline </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.addressSearch")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\naddressSearch" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.addressSearchDescription"
                    )
                  }}
                </td>
                <td>{{ $t("ModuleAddressSearch." + module.addressSearch) }}</td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-cellphone </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{ $t("Settings.tableRowsSupportModules.brandApp") }}</b>
                  {{ !readOnlyMainSettings ? "\nbrandApp" : "" }}
                </td>
                <td>
                  {{
                    $t("Settings.tableRowsSupportModules.brandAppDescription")
                  }}
                </td>
                <td>
                  <v-icon v-if="module.brandApp" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>

              <tr>
                <td>
                  <v-icon> mdi-advertisements </v-icon>
                </td>
                <td style="white-space: pre-wrap">
                  <b>{{
                    $t("Settings.tableRowsSupportModules.commercialAds")
                  }}</b>
                  {{ !readOnlyMainSettings ? "\ncommercialAds" : "" }}
                </td>
                <td>
                  {{
                    $t(
                      "Settings.tableRowsSupportModules.commercialAdsDescription"
                    )
                  }}
                </td>
                <td>
                  <v-icon v-if="module.commercialAds" color="green">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else color="red"> mdi-cancel </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItem()"> {{ $t("btnReload") }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

import {
  vehicleAllocation,
  Status,
  AutoSendType,
  Type,
  DirectSendType,
  PhoneClient,
  PhoneDriver,
  ReceiptEdit,
  ShowDestination,
  Chat,
  BreakType,
  AcceptSupportOrderType,
  SendSupportOrderType,
  SendtypeSupportOrderType,
  TypeSupportOrderType,
  SmsGateway,
  PhoneGateway,
} from "../enums/TaxiserviceEnum";
import { Country } from "../enums/CountryEnum";
import PaymentOptionList from "../components/PaymentOptionList.vue";
import SmsTemplateList from "../components/SmsTemplateList.vue";

export default {
  components: {
    PaymentOptionList,
    SmsTemplateList,
  },
  data() {
    return {
      tabs: null,
      taxiserviceId: null,
      //nastavenia modulov
      module: moduleDefaultSettings,
      //enums
      ShowDestination,
      Country,
      vehicleAllocation,
      AutoSendType,
      DirectSendType,
      Type,
      ReceiptEdit,
      Status,
      PhoneClient,
      PhoneDriver,
      Chat,
      BreakType,
      AcceptSupportOrderType,
      SendSupportOrderType,
      SendtypeSupportOrderType,
      TypeSupportOrderType,
      SmsGateway,
      PhoneGateway,
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      editMode: false,
      itemId: null,
      item: null,
      cachedItem: null,
      readOnlyMainSettings: true, //Pre role taxisluzby niektore polia zablokovane. Pre role taxximo sú povolené.
      disabledAllSettings: true, //Obycajny dispecer a manazer taxisluzby nemozu editovat ziadne nastavenia.

      showSmsPassword: false,
      showPhonePassword: false,

      //Support Order Types
      loadingDataSupportOrderTypes: false,
      loadingCreateSupport: false,
      loadingEditSupport: false,
      loadingDeleteSupport: false,
      formEditSupportValidation: false,
      formCreateSupportValidation: false,
      formDeleteSupportValidation: false,
      editSupportOrderDialog: false,
      createSupportOrderDialog: false,
      deleteSupportOrderDialog: false,
      gridHeadersSupportOrderTypes: [
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.id"),
          value: "id",
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.type"),
          value: "type",
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.accept"),
          value: "accept",
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.send"),
          value: "send",
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.sendType"),
          value: "sendType",
        },
        {
          text: this.$t("Settings.tableHeadersSupportOrderTypes.moveAutomat"),
          value: "moveAutomat",
        },
      ],
      gridItemsSupportOrderTypes: [],
      editItemSupportOrderTypes: {
        id: null,
        type: "INSTANT",
        accept: "AUTO",
        send: "MANUAL",
        sendType: "NONE",
      },
      createItemSupportOrderTypes: {
        type: "INSTANT",
        accept: "AUTO",
        send: "MANUAL",
        sendType: "NONE",
      },
      sliderConfimationCheck: ["❌", "✔"],
      deleteItemSupportOrderTypes: {
        id: 0,
        type: "INSTANT",
        slider: 0,
      },
    };
  },

  methods: {
    enableEditMode(enabled) {
      this.editMode = enabled;
      //zablokovat editovanie nastavení o pracovnej zmene a vzdy predpnut na vypnute
      if (this.module.worklog == false) {
        this.item.approvalWorklog = false;
        this.item.validateMileages = false;
      }
    },
    getItem() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/${this.taxiserviceId}`
        )
        .then((response) => {
          this.item = response.data;
          this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    reset() {
      this.enableEditMode(false);
      //this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.item = Object.assign({}, this.cachedItem);
    },
    validateAutomatPriorityTime2(v) {
      // Custom validation logic for automatPriorityTime2
      return (
        v > this.item.automatPriorityTime3 ||
        this.$t("Settings.automatPriorityTime2_validation")
      );
    },
    validateAutomatPriorityTime3(v) {
      // Custom validation logic for automatPriorityTime3
      return (
        v < this.item.automatPriorityTime2 ||
        this.$t("Settings.automatPriorityTime3_validation")
      );
    },
    submitForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.cachedItem = Object.assign({}, this.item);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
    getItemsSupportOrderTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice/support-type-order`
        )
        .then((response) => {
          this.gridItemsSupportOrderTypes = response.data;
          //this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    editSupportOrderType(supportOrderId) {
      console.log("detail: ", supportOrderId);
      var selectedItem;
      selectedItem = this.gridItemsSupportOrderTypes.filter(function (elem) {
        if (elem.id == supportOrderId) return elem;
      });
      if (selectedItem.length > 0) {
        console.log(selectedItem);

        this.editItemSupportOrderTypes = selectedItem[0];
        this.editSupportOrderDialog = true;
      } else {
        console.log("submitEditSupportDialog missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },
    createSupportOrderType() {
      this.createItemSupportOrderTypes.type = "";
      this.createItemSupportOrderTypes.accept = "";
      this.createItemSupportOrderTypes.send = "";
      this.createItemSupportOrderTypes.sendType = "";
      //this.createSupportOrderDialog.moveAutomat = false;
      this.createSupportOrderDialog = true;
    },
    deleteSupportOrderType(supportOrderId) {
      var selectedItem;
      selectedItem = this.gridItemsSupportOrderTypes.filter(function (elem) {
        if (elem.id == supportOrderId) return elem;
      });
      if (selectedItem.length > 0) {
        console.log(selectedItem);
        this.deleteItemSupportOrderTypes.id = selectedItem[0].id;
        this.deleteItemSupportOrderTypes.type = selectedItem[0].type;
        this.deleteItemSupportOrderTypes.slider = 0;
        this.deleteSupportOrderDialog = true;
      } else {
        console.log("submitDeleteSupportDialog missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },

    submitEditSupportDialog() {
      //const isValid = this.$refs.formEditSupport.validate();
      if (this.formEditSupportValidation) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice/support-type-order`,
            this.editItemSupportOrderTypes
          )
          .then((response) => {
            this.loadingEditSupport = false;
            this.editSupportOrderDialog = false;
            this.getItemsSupportOrderTypes();
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      } else {
        console.log("submitEditSupportDialog validation problem");
        (this.loadingEditSupport = false), (this.snackbar.show = true);
        this.snackbar.message = this.$t("msgDataValidation");
        this.snackbar.color = "error";
      }
    },
    submitCreateSupportDialog() {
      //const isValid = this.$refs.formCreateSupport.validate();
      var selectedItem;
      var orderType = this.createItemSupportOrderTypes.type;
      //Overenie, ci sa v zozname nenachadza uz identicky typ, aby sa nevytvorila duplicita, pripadne neprepisal existujuci zaznam
      selectedItem = this.gridItemsSupportOrderTypes.filter(function (elem) {
        if (elem.type == orderType) return elem;
      });
      if (this.formCreateSupportValidation && selectedItem.length == 0) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice/support-type-order`,
            this.createItemSupportOrderTypes
          )
          .then((response) => {
            this.loadingCreateSupport = false;
            this.createSupportOrderDialog = false;
            this.getItemsSupportOrderTypes();
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      } else {
        console.log("submitCreateSupportDialog validation problem");
        (this.loadingCreateSupport = false), (this.snackbar.show = true);
        this.snackbar.message = this.$t("msgDataValidation");
        this.snackbar.color = "error";
      }
    },
    submitDeleteSupportDialog() {
      //const isValid = this.$refs.formCreateSupport.validate();
      console.log(this.deleteItemSupportOrderTypes.slider);
      if (
        this.formDeleteSupportValidation &&
        this.deleteItemSupportOrderTypes.slider == 100
      ) {
        axios
          .delete(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice/support-type-order/` +
              this.deleteItemSupportOrderTypes.id
          )
          .then((response) => {
            (this.loadingDeleteSupport = false),
              (this.deleteSupportOrderDialog = false);
            this.getItemsSupportOrderTypes();
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgDeleteError");
            this.snackbar.color = "error";
          });
      } else {
        console.log("submitDeleteSupportDialog validation problem");
        this.loadingDeleteSupport = false;
        this.snackbar.show = true;
        if (this.deleteItemSupportOrderTypes.slider < 100) {
          this.snackbar.message = this.$t("msgConfirmSlider");
        } else {
          this.snackbar.message = this.$t("msgDataValidation");
        }
        this.snackbar.color = "error";
      }
    },
  },
  created() {
    //ID taxisluzby
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },
  mounted() {
    this.itemId = this.$route.params.id;
    this.getItem();
    this.getItemsSupportOrderTypes();
    //Zablokovanie zakladnych poli proti editovaniu pre pouzivatelov taxisluzby. Pre taxximo poutivatelov je povolene
    let userRoles = this.$store.state.user.roleList;
    let enabledRoles = ["ADMIN", "SUPPORT"];
    this.readOnlyMainSettings =
      userRoles.some((a) => (enabledRoles.includes(a) ? false : true)) == true
        ? true
        : false;
    //Zablokovanie editovania vsetkych informacii pre obycajneho dispecera a manazera taxisluzby (DISPATCHER, MANAGER)
    let enabledRolesEdit = ["ADMIN", "SUPPORT", "OWNER"];
    this.disabledAllSettings = !userRoles.some((ai) =>
      enabledRolesEdit.includes(ai)
    );
  },
  watch: {
    "item.immediateAutomat"(newVal) {
      if (newVal === "LOCALITY") {
        this.item.localityCheck = true;
      } else {
        this.item.localityCheck = false;
      }
    },
  },
};
</script>
